import client from 'services/client';

import {
  GetShopkeeperProfileRequest,
  GetShopkeeperProfileResponse,
} from './types';

export const getShopkeeperProfile = async ({
  shopkeeper_id,
}: GetShopkeeperProfileRequest): Promise<GetShopkeeperProfileResponse> => {
  return await client.get(`v1/shopkeeper/profile/${shopkeeper_id}`);
};
