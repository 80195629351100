import React, { useState } from 'react';
import { format } from 'date-fns';
import { Calendar as CalendarIcon } from 'lucide-react';
import { DateRange } from 'react-day-picker';
import TagManager from 'react-gtm-module';

import { Button } from 'components/Button';
import Calendar from 'components/Calendar';
import { Popover, PopoverContent, PopoverTrigger } from 'components/Popover';
import { cn } from 'utils/cn';
import { formatDateRange, getDateThreeMonthsAgo } from 'utils/date';

export interface DateRangePickerProps {
  date?: DateRange | undefined;
  className?: string | undefined;
  onChange: (date: DateRange | undefined) => void;
  onChangeOpenStatus?: (open: boolean) => void;
}

const dateThreeMonthsAgo = getDateThreeMonthsAgo();

function DateRangePicker({
  className,
  onChange,
  onChangeOpenStatus,
  ...props
}: DateRangePickerProps) {
  const [date, setDate] = useState<DateRange | undefined>(props.date);

  const handleOpenChange = (open: boolean) => {
    onChangeOpenStatus?.(open);

    if (open) {
      TagManager.dataLayer({
        dataLayer: {
          event: 'user_flow',
          category: 'clic',
          action: 'clic_boton',
          label: 'Abrir calendario',
        },
      });
    }

    if (!open) {
      onChange(date);
    }
  };

  return (
    <div className={cn('grid gap-2', className)}>
      <Popover onOpenChange={handleOpenChange}>
        <PopoverTrigger asChild>
          <Button
            id="date_range"
            variant={'outline'}
            className={cn(
              'h-14 w-full justify-between rounded-lg text-left font-normal',
              !date && 'text-muted-foreground',
            )}
          >
            {date?.from ? (
              formatDateRange(date.from, date.to)
            ) : (
              <span>Elije la fecha</span>
            )}
            <CalendarIcon className="ml-2 h-4 w-4" />
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-auto p-0" align="start">
          <Calendar
            initialFocus
            mode="range"
            defaultMonth={date?.from}
            fromDate={dateThreeMonthsAgo}
            selected={date}
            onSelect={setDate}
            numberOfMonths={2}
          />
        </PopoverContent>
      </Popover>
    </div>
  );
}

export default React.memo(DateRangePicker);
