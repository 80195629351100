import client from 'services/client';

import {
  GetCouponDetailsRequest,
  GetCouponDetailsResponse,
  RedeemCouponRequest,
  RedeemCouponResponse,
} from './types';

export const getCouponDetails = async ({
  coupon_code,
}: GetCouponDetailsRequest): Promise<GetCouponDetailsResponse> => {
  return await client.get(`v1/coupon-code/${coupon_code}`);
};

export const redeemCoupon = async ({
  amount,
  coupon_code,
  shopkeeper_id,
}: RedeemCouponRequest): Promise<RedeemCouponResponse> => {
  return await client.post(`v1/redeem-coupon/${shopkeeper_id}`, {
    code: coupon_code,
    amount,
  });
};
