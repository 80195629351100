import React, { useCallback } from 'react';
import { ChevronLeft as ChevronLeftIcon } from 'lucide-react';
import parse from 'html-react-parser';

import { Button } from 'components/Button';
import { Content } from 'services/content';
import { Coupon } from 'services/coupon';
import { useNavigate } from 'react-router-dom';

export interface CouponDetailsProps {
  coupon: Coupon;
  redeemCoupon: () => void;
  termsAndConditions?: Content;
}

function CouponDetails({
  coupon,
  redeemCoupon,
  termsAndConditions,
}: CouponDetailsProps) {
  const navigate = useNavigate();

  const handleBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  return (
    <>
      <section className="flex flex-col gap-6 px-4 py-8">
        <img src={coupon.banner_url} alt={coupon.description} />
        <Button
          id="redeem_coupon"
          className="self-center"
          onClick={redeemCoupon}
          variant="tertiary"
        >
          Canjear cupón
        </Button>
        <div className="flex flex-col gap-2 rounded-xl bg-white p-3">
          <div className="flex items-center gap-2">
            <img className="h-8 w-8" src="/terminos.webp" alt="terminos" />
            <h2 className="font-serif text-xl font-semibold text-black">
              Términos y condiciones
            </h2>
          </div>
          <div className="px-6" id="terms">
            {termsAndConditions && parse(termsAndConditions.content)}
          </div>
        </div>
      </section>
    </>
  );
}

export default React.memo(CouponDetails);
