import { useCallback, useEffect, useState } from 'react';
import { DateRange } from 'react-day-picker';
import TagManager from 'react-gtm-module';
import { Helmet } from 'react-helmet';

import DateRangePicker from 'components/DateRangePicker';
import Layout from 'components/Layout';
import Loader from 'components/Loader';
import { ToastAction } from 'components/Toast';
import { useAuth } from 'hooks/useAuth';
import { useToast } from 'hooks/useToast';
import redemptionHistoryService, {
  Promotion,
  Redemptions,
} from 'services/redemptionHistory';

import PromotionItem from './components/PromotionItem';
import { formatISOLocalDate, getDateThreeMonthsAgo } from '../../utils/date';
import FilterTabs, {
  CouponHistoryType,
  FilterOptions,
} from 'components/FilterTabs';

const filterOptions: FilterOptions<CouponHistoryType>[] = [
  {
    label: 'Vigentes hoy',
    value: 'active',
  },
  {
    label: 'Vencidos',
    value: 'inactive',
  },
];

const dateThreeMonthsAgo = getDateThreeMonthsAgo();
const redemptionsInitialValue: Redemptions = {
  count: 0,
  total_revenue: 0,
};

function HistoryScreen() {
  const { session } = useAuth();
  const { toast } = useToast();
  const [date, setDate] = useState<DateRange | undefined>();
  const [_, setIsCalendarOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [redemptions, setRedemptions] = useState<Redemptions>(
    redemptionsInitialValue,
  );
  const [promotions, setPromotions] = useState<Promotion[]>([]);
  const [filter, setFilter] = useState<CouponHistoryType>('active');

  const filteredPromotions = promotions.filter((promo) =>
    filter === 'active' ? promo.promotion.isActive : !promo.promotion.isActive,
  );

  const fetchData = useCallback(async () => {
    const dateFrom = date ? date.from : dateThreeMonthsAgo;
    const dateTo = date ? date.to || date.from : new Date();
    const errors: any[] = [];

    const [totalRedemptionsResponse, redemptionHistoryResponse] =
      await Promise.allSettled([
        redemptionHistoryService.getTotalRedemptions({
          shopkeeper_id: session?.shopkeeper_id ?? 0,
          date_from: formatISOLocalDate(dateFrom!),
          date_to: formatISOLocalDate(dateTo!) || formatISOLocalDate(dateFrom!),
        }),
        redemptionHistoryService.getRedemptionHistory({
          shopkeeper_id: session?.shopkeeper_id ?? 0,
          date_from: formatISOLocalDate(dateFrom!),
          date_to: formatISOLocalDate(dateTo!) || formatISOLocalDate(dateFrom!),
        }),
      ]);
    if (totalRedemptionsResponse.status === 'fulfilled') {
      setRedemptions(totalRedemptionsResponse.value.data);
    } else {
      setRedemptions(redemptionsInitialValue);
      errors.push(totalRedemptionsResponse.reason);
    }

    if (redemptionHistoryResponse.status === 'fulfilled') {
      setPromotions(redemptionHistoryResponse.value.data);
    } else {
      setPromotions([]);
      if (redemptionHistoryResponse.reason.code !== 'history_not_found') {
        errors.push(redemptionHistoryResponse.reason);
      }
    }

    errors.forEach((e: any) => {
      TagManager.dataLayer({
        dataLayer: {
          event: 'user_flow',
          category: 'system_message',
          action: 'error',
          label: e.message,
        },
      });
      toast({
        action: <ToastAction altText="Cerrar">Cerrar</ToastAction>,
        description: e.message,
        duration: 3000,
        variant: 'destructive',
      });
    });
    setIsLoading(false);
  }, [session?.shopkeeper_id, toast, date]);

  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'page_view',
        pagePath: '/tendero-historial-de-canje',
        pageTitle: 'Tendero: Historial de canje',
      },
    });
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <>
      <Helmet>
        <title>Tu historial de canjes | Descuentón</title>
      </Helmet>
      {isLoading ? (
        <Loader />
      ) : (
        <Layout title="Cupones" variant="neutral">
          <section className="w-full sm:mx-auto sm:max-w-sm">
            <div className="mt-6 flex flex-col gap-4 px-4">
              <p className="text-sm font-bold text-black">Resumen</p>
              <DateRangePicker
                date={date}
                onChange={setDate}
                onChangeOpenStatus={setIsCalendarOpen}
              />
            </div>
            {date === undefined && (
              <p className="mx-4 mt-4 text-sm font-bold text-black">
                Resultados para: Últimos 3 meses
              </p>
            )}
            <div className="mx-auto mt-4 flex max-w-36 divide-x rounded-lg bg-gradient-to-b from-blue-500 to-blue-300 px-3 py-2">
              <div className="flex flex-1 flex-col items-center justify-center">
                <p className="text-2xs text-white">Canjes realizados</p>
                <p className="font-serif text-xl font-bold text-tertiary">
                  {redemptions.count}
                </p>
              </div>
            </div>
            <div className="mt-4 flex flex-col gap-4">
              <FilterTabs
                options={filterOptions}
                filter={filter}
                setFilter={setFilter}
              />
              <div className="mx-4 flex flex-col p-3">
                {filteredPromotions.length > 0 ? (
                  <div className="divide-neutral-weak border-neutral-weak flex flex-col divide-y border-b">
                    {filteredPromotions.map((promotion) => (
                      <PromotionItem
                        key={promotion.promotion_id}
                        {...promotion}
                      />
                    ))}
                  </div>
                ) : (
                  <div className="my-24 flex flex-col items-center gap-4 text-center">
                    <img
                      className="h-[4.28125rem] w-[6.25rem]"
                      src="/sin-movimientos.webp"
                      alt="historial"
                    />
                    <p className="font-serif text-xl font-bold text-black">
                      No hemos encontrado cupones{' '}
                      {filter === 'active' ? 'activos' : 'inactivos'}
                    </p>
                  </div>
                )}
              </div>
            </div>
          </section>
        </Layout>
      )}
    </>
  );
}

export default HistoryScreen;
