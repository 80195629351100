import client from 'services/client';

import { GetContentResponse } from './types';

export const getTermsAndConditions = async (): Promise<GetContentResponse> => {
  return await client.get('v1/content/terms');
};

export const getPrivacyPolicies = async (): Promise<GetContentResponse> => {
  return await client.get('v1/content/site_privacy');
};

export const getTermsAndConditionsByPromotion = async (
  promotionId: string,
): Promise<GetContentResponse> => {
  return await client.get(`v1/content/terms/${promotionId}`);
};
